<template>
  <div class="SunmmaryTeacherInfoDialog">
    <el-dialog
      title="学生档案预览"
      :visible="exportStuInfoDialog"
      @close="close"
      width='1080px'
      :modal-append-to-body="false"
      center>
      <div class="dialog-submit" @click="pdfDownload()">导出pdf</div>
      <div id="pdfDom1" ref="orderForm1">
        <el-form
          :model="basicForm"
          :rules="basicRules"
          ref="basicForm"
          label-position="right"
          label-width="120px"
          :disabled="pageType == 'view'">
          <div class="basic-form basic-form-view">

            <div class="basic-info">
              <el-row>
                <el-col :span="12">
                  <el-row>
                    <el-col :span="8">
                      <div class="baseCol baseCol-name baseCol-checked">学生姓名</div>
                    </el-col>
                    <el-col :span="16">
                      <div class="baseCol baseCol-student">{{basicForm.studentName}}</div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <div class="baseCol baseCol-studyNo baseCol-checked">性别</div>
                    </el-col>
                    <el-col :span="16">
                      <div class="baseCol baseCol-studyNo baseCol-sex">
                        <div v-if="basicForm.sex==1">男</div>
                        <div v-else>女</div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <div class="baseCol baseCol-id baseCol-checked">身份证号</div>
                    </el-col>
                    <el-col :span="16">
                      <div class="baseCol baseCol-idCard">{{basicForm.idCard||''}}</div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12">
                  <el-row>
                    <el-col :span="8">
                      <div class="baseCol baseCol-img baseCol-checked">头像</div>
                    </el-col>
                    <el-col :span="16">
                      <div class="baseCol-user">
                        <img :src="basicForm.stuImg || avatar" alt="">
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <div class="baseCol  baseCol-studyNo baseCol-checked">班级</div>
                </el-col>
                <el-col :span="8">
                  <div class="baseCol  baseCol-studyNo  baseCol-idCard">{{basicForm.organName}}</div>
                </el-col>
                <el-col :span="4">
                  <div class="baseCol baseCol-idCard baseCol-checked">学号</div>
                </el-col>
                <el-col :span="8">
                  <div class="baseCol baseCol-idCard">{{basicForm.studyNo}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <div class="baseCol baseCol-studyNo baseCol-checked">唯一号</div>
                </el-col>
                <el-col :span="20">
                  <div class="baseCol baseCol-idCard">{{basicForm.onlyCode}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                    <div class="baseCol baseCol-studyNo baseCol-checked">序列号</div>
                </el-col>
                <el-col :span="8">
                    <div class="baseCol baseCol-idCard">{{ basicForm.serialNumber }}</div>
                </el-col>
                <el-col :span="4">
                  <div class="baseCol baseCol-idCard baseCol-checked">消费账号</div>
                </el-col>
                <el-col :span="8">
                  <div class="baseCol baseCol-idCard">{{basicForm.consumNumber}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <div class="baseCol baseCol-studyNo baseCol-checked">学生类型</div>
                </el-col>
                <el-col :span="20">
                  <div class="baseCol baseCol-idCard">{{ basicForm.studentType | studentTypeFilter }}</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>

        <div v-if="previewPdfList.archivesTypeIds.length > 0" class="detail-form">
          <stu-info-fill-main
            ref="stuInfoFill"
            :mode="'view'"
            :school-id="schoolId"
            :stu-basic-info="basicForm"
            :templateInfo="newOriBasicForm"
          ></stu-info-fill-main>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { MTreeSelect, StuInfoFillMain } from "common-local";
import { listToTree } from "@/libs/utils.js";
import { getToken } from "@/libs/auth";
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default {
  name: 'SunmmaryTeacherInfoDialog',
  components: {
    MTreeSelect,
    StuInfoFillMain
  },
  props: {
    previewPdfList: {
      type: Object
    },
    exportStuInfoDialog: {
      type: Boolean,
      default: false
    },
    basicForm: {
      type: Object
    }
  },
  data () {
    return {
      pageType: 'add',
      schoolId: '',
      showCourseSelection: false,
      oriBasicForm: {}, // 重置用
      basicForm: {
        stuImg: '',
        studentName: '',
        remark: '',
        id: '',
        schoolId: '',
        idCard: '',
        studyNo: '',
        onlyCode: '',
        status: 1,
        sex: '1',
        classType: 1,
        organId: '',
        courseSelection: ''
      },
      isHasStu: false,
      courseSelectionList: [],
      basicRules: {
        studentName: [{
          required: true,
          message: '请输入学生姓名',
          trigger: ['change', 'blur']
        }],
        sex: [{
          required: true,
          message: '请选择性别',
          trigger: ['change', 'blur']
        }],
        onlyCode: [
          {
            required: true,
            message: '请输入唯一号',
            trigger: ['change', 'blur']
          },
        ],

      },
      submitFormLoading: false,
      // 学生档案相关
      detailFormShow: true,
      newOriBasicForm: []
    }
  },
  filters: {
    studentTypeFilter(val) {
      let map = {
        '0': '住宿',
        '1': '走读',
        '2': '半读'
      }
      return val && map[val] ? map[val] : ''
    }
  },
  watch: {
    exportStuInfoDialog: {
      handler (nv) {
        if (nv == true) {
          this.getExportData()
          this.schoolId = this.basicForm.schoolId
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    triangleUp () {
      return require("@/assets/images/stuInfoTemplate/triangle-up.png");
    },
    triangleDown () {
      return require("@/assets/images/stuInfoTemplate/triangle-down.png");
    },
    uploadAvatar () {
      return require("@/assets/images/upload-avatar.png");
    },
    avatar () {
      return require('@/assets/images/upload-avatar.png')
    },
    action () {
      return "/api/school/common/uploadFile";
    },
    delUrl () {
      return require("@/assets/images/school-info-del.png");
    },
    infoUrl () {
      return require("@/assets/images/school-info-icon.png");
    },
    moveActiveUrl () {
      return require("@/assets/images/school-info-move.png");
    },
    moveUrl () {
      return require("@/assets/images/school-info-moveN.png");
    },
    archivesCompletenessUrl () {
      return require("@/assets/images/archivesCompleteness.png");
    },
    headers () {
      return {
        Authorization: getToken()
      };
    }
  },
  methods: {
    /**
     * @Descripttion: 导出学生档案
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: lyx
     * @Date: 2023-01-06 15:31:48
     */
    pdfDownload () {
      this.close()
      let that =this
      var mainRight = document.getElementById('pdfDom1');
      const _domInfo = mainRight.getBoundingClientRect();
      // mainRight.style.width= '1200px'
      console.log(mainRight, 'mainRight', _domInfo.height);
      html2canvas(mainRight, {
        allowTaint: true,
        useCORS: true,
        scale: 2, // 提升画面质量，但是会增加文件大小
        width: _domInfo.width,
        height: _domInfo.height,
        windowWidth: _domInfo.width,
        windowHeight: _domInfo.height,
      }).then(function (canvas) {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        //let pdfWidth = 0
        var pageData = canvas.toDataURL('image/jpeg', 0.4);
        // if (that.previewPdfList.archivesTypeIds.length > 0) {
        //   pdfWidth = (contentWidth + 10) / 1.5 * 0.3;
        // } else {
        //   pdfWidth = (contentWidth + 10) / 1.5 * 0.2;
        // }
        let overlayVal = 200
        if (contentHeight < 1788) {
          overlayVal = 1988 - contentHeight;
        }
        var pdfWidth = ((contentWidth + 10) / 2) * 0.75;
        var pdfHeight = (contentHeight + overlayVal) / 1.5 * 0.6; // 500为底部留白
        var imgWidth = pdfWidth;
        var imgHeight = (contentHeight / 2 * 0.75); //内容图片这里不需要留白的距离
        let pdf = new JsPDF('', 'pt', [pdfWidth, pdfHeight])
        pdf.addImage(pageData, 'jpeg', 55, 0, imgWidth, imgHeight);
        pdf.save('学生档案' + '.pdf');
      });
    },
    /**
     * @Descripttion: 获取学生档案导出数据
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: lyx
     * @Date: 2023-01-06 15:31:37
     */
    getExportData () {
      if (this.previewPdfList.archivesTypeIds.length > 0) {
        this.previewPdfList.archivesTypeIds = this.previewPdfList.archivesTypeIds.join(',')
        this._fet('/school/schoolArchivesData/getExportData', this.previewPdfList).then((res) => {
          if (res.data.code == '200') {
            this.newOriBasicForm = res.data.data
            console.log(this.newOriBasicForm, 'this.newOriBasicForm');
          }
        })
      }
    },
    close () {
      this.$emit('closeStuDialog')
    },
  },
  created () {
    // console.log(this.previewPdfList, 'previewPdfList');
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table-div-view {
//   width: 990px !important;
}
.dialog-submit {
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #3C7FFF;
  border-radius: 4px;
  background: #3C7FFF;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
}
#pdfDom1 {
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
}
.page {
  padding: 16px;
  height: calc(100vh - 140px);
  max-width: 1240px;
  margin: 0 auto;
  // display: flex;
  // flex-direction: column;
  overflow-y: auto;
}
.exportDialog {
  margin-top: 10px;

  .exportDialog-title {
    display: flex;
    align-items: center;

    .exportDialog-text {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363a40;
      margin-right: 16px;
    }

    .exportDialog-reset {
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #e8f1fc;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3C7FFF;
    }
  }

  .exportDialog-content {
    width: 544px;
    background: #ffffff;
    border: 1px solid #edeff2;
    border-radius: 2px;
    margin-top: 20px;

    .exportDialog-content-title {
      display: flex;
      line-height: 40px;
      background: #f5f7fa;

      .exportDialog-content-sort {
        width: 80px;
        border-right: 1px solid #edeff2;
        text-align: center;
      }

      .exportDialog-content-name {
        flex: 1;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }
    .exportDialog-content-title-sort {
      display: flex;
      line-height: 40px;
      .exportDialog-content-sort-title {
        width: 80px;
        border-right: 1px solid #edeff2;
        border-bottom: 1px solid #edeff2;
        text-align: center;
      }

      .exportDialog-content-name-text {
        flex: 1;
        padding-left: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #edeff2;
      }
    }
  }

  .exportDialog-list {
    display: flex;
    background-color: #fff;
    height: 40px;
    line-height: 40px;

    .exportDialog-sort {
      width: 80px;
      height: 40px;
      line-height: 40px;
      border-right: 1px solid #edeff2;
      border-bottom: 1px solid #edeff2;
      text-align: center;

      &:last-child {
        border-bottom: 0;
      }
    }

    .exportDialog-name {
      flex: 1;
      height: 40px;
      line-height: 40px;
      display: flex;
      border-bottom: 1px solid #edeff2;
      position: relative;

      .exportDialog-name-info {
        margin-left: 20px;
      }

      .exportDialog-icon {
        position: absolute;
        right: 20px;
        top: 14px;
        display: flex;

        .move-icon {
          width: 14px;
          height: 14px;
          margin-right: 26px;
          display: block;
        }

        .del-icon {
          width: 12px;
          height: 12px;
          display: block;
        }
      }
    }
  }

  .exportDialog-info {
    margin-top: 20px;
    height: 14px;
    line-height: 14px;
    display: flex;

    .dialog-info-icon {
      width: 13px;
      height: 13px;
    }

    .dialog-info-text {
      margin-left: 5px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f56c6c;
    }
  }

  .dialog-footer {
    display: flex;
    // margin: 0 auto;
    margin-top: 26px;

    .dialog-cancel,
    .dialog-submit {
      width: 120px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid #3C7FFF;
      border-radius: 4px;
      color: #3C7FFF;
    }

    .dialog-submit {
      background: #3C7FFF;
      color: #ffffff;
      margin: 0 24px 0 140px;
    }
  }
}
.archivesCompleteness {
  width: 100%;
  height: 40px;
  line-height: 40px;
  // border: 1px solid black;
  background: #edf4fa;
  margin-top: 32px;
  display: flex;
  align-items: center;

  .archivesCompleteness-icon {
    display: inline-block;
    margin-left: 12px;
    width: 13px;
    height: 8px;
  }

  .archivesCompleteness-title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6c84a6;
    margin: 0 10px;
  }
  .archivesCompleteness-value {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3C7FFF;
  }
}
// 隐藏滚动条且可滚动
.page::-webkit-scrollbar {
  width: 0 !important;
}

// 公共-有分割线的标题
::v-deep .line-title {
  text-align: center;
  display: flex;
  flex-direction: row;
}
::v-deep .line-title:before {
  content: "";
  flex: 1 1;
  width: 400px;
  margin: auto 20px;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(135deg, #ffffff, #ffffff),
    linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
}
::v-deep .line-title:after {
  content: "";
  flex: 1 1;
  width: 400px;
  margin: auto 20px;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(135deg, #ffffff, #ffffff),
    linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
  border-bottom: 1px solid;
  border-image: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0) 0%,
    #e6e6e6 100%
  );
}
// 公共-按钮
.btn {
  width: 88px;
  height: 36px;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
.btn-reset {
  background: #ebf1f7;
  color: #737373;
}
.btn-confirm {
  background: #3C7FFF;
  color: #ffffff;
}

// 基础信息
.basic {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
}
// 基础信息-头部
.basic-header,
.basic-header-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-bottom: 20px;
  .basic-header-flag {
    width: 1px;
    height: 16px;
    background: #bfbfbf;
    margin: 0 20px;
  }
  .back {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
    cursor: pointer;
  }

  .basic-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .exportBtn {
    width: 80px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #3C7FFF;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}
.basic-header-view {
  position: absolute;
  top: 13px;
  left: 20px;
}
// 基础信息-表单
.basic-form,
.basic-form-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 56px 50px 40px 0px;
  box-sizing: border-box;
  .basic-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;

    .basic-avatar-div {
      width: 104px;
      height: 104px;
    }

    .basic-avatar-img img {
      width: 100%;
      height: 100%;
    }

    .basic-avatar-upbtn {
      border: 1px dashed #dbdbdb;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      cursor: pointer;

      img {
        width: 52px;
        height: 58px;
      }

      div {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        border-radius: 0px 0px 4px 4px;
        background: linear-gradient(0deg, #f2f2f2 0%, #ffffff 100%);
        width: 100%;
        padding: 8px 0;
        text-align: center;
      }
    }

    .basic-avatar-tip {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bfbfbf;
      margin-top: 10px;
    }
  }

  .basic-info {
    flex: 1;
  }
}
.basic-form-view {
  padding: 0;
}
// 基础信息-按钮
.basic-btn-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .btn-confirm {
    margin-left: 16px;
  }
}

// 展示或收起按钮
.show-stow-btn {
  margin-top: 10px;
  height: 48px;
  background: #f7fafc;
  border-radius: 6px 6px 0px 0px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #3C7FFF;
  cursor: pointer;

  img {
    width: 12px;
    height: 8px;
    margin-left: 8px;
    margin-bottom: 2px;
  }
}

// 更多信息表
.detail-form {
  background-color: #ffffff;
  border-radius: 0px 0px 6px 6px;
  // flex: 1;
}

// 加边框、两端对齐及相关修改
::v-deep .el-form-item {
  margin-bottom: 0;
  border: 1px solid black;

  .el-form-item__label {
    text-align-last: justify; // 最后一行两端对齐
    text-align: justify; // 两端对齐
    padding: 0 12px;
    // box-sizing: border-box;
    // background-color:#edf4fa;
  }

  .el-form-item__content {
    border-left: 1px solid black;
    min-height: 36px;
    position: relative;

    .form-value-show-only,
    .el-radio,
    .el-checkbox-group,
    .explain {
      padding-left: 15px;
    }
    .el-checkbox-group {
      height: 36px;
    }
    .el-radio-group {
      height: 36px;
    }
    .upload-demo {
      padding-left: 5px;
    }

    .avatar-uploader {
      padding: 5px 10px;
    }

    .input-div {
      position: absolute;
      height: 100%;
      .input {
        .el-input__inner {
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 100%;
          text-align: center;
        }
      }
    }
  }
}
// label两端对齐
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: unset;
}
::v-deep .el-form-item.is-required {
  position: relative;
}
::v-deep .el-form-item.is-required::before {
  position: absolute;
  content: "*";
  color: #f56c6c;
  top: 5px;
  left: 5px;
}
// 组件组
::v-deep .comps-group-div {
  margin-top: 22px;
  .comps-group-item {
    padding: 0;
  }
}
// 基础信息-标题
.basic-title {
  margin-left: 144px;
}
// 基础信息-按钮
.basic-btn-div {
  margin-left: 193px;
}
.baseCol {
  border: 1px solid #919599;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #363b40;
  min-height: 42px;
  // padding: 0 12px;
  box-sizing: border-box;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.baseCol-student {
  border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-sex {
  border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-id {
  border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-idCard {
  border-top: 1px solid rgba(255, 255, 255, 0);
  border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-studyNo {
  border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-img {
  height: 126px;
  line-height: 126px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-user {
  height: 124px;
  border: 1px solid #919599;
  border-left: 1px solid rgba(255, 255, 255, 0);
  img {
    display: block;
    margin: 10px auto 0;
    width: 104px;
    height: 104px;
    border-radius: 8px;
  }
}
.baseCol-checked {
  background-color: #edf4fa;
}
.view-title {
  width: 100%;
  height: 14px;
  line-height: 14px;
  display: flex;
  margin: 42px 0 16px;

  .view-flag {
    width: 3px;
    height: 14px;
    background-color: #3C7FFF;
    margin-right: 8px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
  }
}
.page-view {
  max-width: 100%;
  box-sizing: border-box;

  .basic {
    position: relative;
    // padding: 28px 304px 24px;
    margin: 0 auto;
    width: 1000px !important;
  }
  ::v-deep .stu-info-fill-main {
    padding: 0 0 24px 0 !important;
  }

  // padding: 0 324px;
  // padding: 0  0 0 324px;
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #919599 !important;
  }
  /deep/ .el-table th.el-table__cell {
    color: #363b40 !important;
    font-size: 14px;
    font-weight: 400;
    background-color: #edf4fa !important;
    border: 1px solid #919599 !important;
    border-top: 1px solid rgba(255, 255, 255, 0) !important;
    &:nth-child(n) {
      border-left: 1px solid rgba(255, 255, 255, 0) !important;
    }
  }
  /deep/ .el-table__body td.el-table__cell {
    border: 1px solid #919599 !important;
    border-top: 1px solid rgba(255, 255, 255, 0) !important ;
    font-size: 14px;
    color: #363b40;
    &:nth-child(n) {
      border-left: 1px solid rgba(255, 255, 255, 0) !important;
      // border-bottom: 1px solid #919599 !important;
    }
  }

  /deep/ .el-form-item {
    border: 1px solid #919599 !important;
    .explainText {
      display: none;
    }
    .upload-demo {
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .el-input.is-disabled .el-input__inner {
      background-color: white !important;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #363b40;
      // height: 100%;
    }
    .el-checkbox-group {
      box-sizing: border-box;
      overflow-y: scroll;
    }
    .el-radio-group {
      width: 100%;
      // padding-top: 10px;
      line-height: 47px !important;
      height: 36px;
      overflow-y: scroll;
    }
    .el-textarea.is-disabled .el-textarea__inner {
      background-color: white !important;
      font-size: 14px;
      color: #363b40;
    }
    .el-form-item__label {
      text-align: center;
      text-align-last: unset;
      font-size: 14px;
      color: #363b40;
      background-color: #edf4fa;
      width: 166px !important;
    }
    .el-form-item__content {
      border-left: 1px solid #919599 !important;
      margin-left: 166px !important;
      .explain {
        text-align: center !important;
        font-size: 14px !important;
        color: #363b40 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .avatar-uploader-icon {
        width: 148px !important;
        height: 148px !important;
        line-height: 148px !important;
      }
      .avatar-uploader {
        margin-top: 21px !important;
        // overflow: unset !important;
      }
      .el-upload {
        cursor: not-allowed !important;
      }
    }
  }
}
</style>
<style lang="scss" >
.page-view .el-input__inner::placeholder {
  color: white !important;
}
.page-view .el-textarea__inner::placeholder {
  color: white !important;
}
</style>
