<template>
    <div>
        <!-- 基础信息 -->
        <div v-if="findTabItem('基础档案')">
            <BasicInfo
                :basicForm="basicForm"
                :basicRules="basicRules"
                :pageType="pageType"
                :avatar="avatar"
                :archivesCompletenessUrl="archivesCompletenessUrl"
                :oriBasicForm="oriBasicForm"
                :detailFormShow="detailFormShow"
                :schoolId="schoolId"
                :tabList="tabList"
                :exportDialog="exportDialog"
                @desensitizeGet="desensitizeGet"
                @getTemplateList="getTemplateList"
                @firstExportBtn="firstExportBtn"
            />
        </div>
        <!-- 学生成绩 -->
        <div v-if="findTabItem('学生成绩')">
            <AchievementList
                :basicForm="basicForm"
                :activeItem="activeItem"
            ></AchievementList>
        </div>
        <!-- 德育量化 -->
        <div v-if="findTabItem('德育量化')">
            <MoralEducation
                :basicForm="basicForm"
            ></MoralEducation>
        </div>
        <!-- 请假记录 -->
        <div v-if="findTabItem('请假记录')">
            <RecordList
                :basicForm="basicForm"
                :activeItem="activeItem"
            ></RecordList>
        </div>

    </div>
</template>

<script>
import { StuInfoFill } from "common-local";
import BasicInfo from "./BasicInfo";
import AchievementList from "./AchievementList";
import RecordList from "./RecordList";
import MoralEducation from "./MoralEducation";

export default {
    name: 'ViewPage',
    components: {
        StuInfoFill,
        BasicInfo,
        AchievementList,
        RecordList,
        MoralEducation,
        // RecordList: () => import(/* webpackChunkName:'RecordList' */"@/components/scrollWrapper/SchoolLeaveRecordAnalysis/RecordList"),
    },
    props: [
        'basicForm',
        'activeItem',
        'basicRules',
        'pageType',
        'avatar',
        'archivesCompletenessUrl',
        'oriBasicForm',
        'detailFormShow',
        'schoolId',
        'tabList',
        'exportDialog'
    ],
    data() {
        return {

        }
    },
    filters: {
        studentTypeFilter(val) {
            let map = {
                '0': '住宿',
                '1': '走读',
                '2': '半读'
            }
            return val && map[val] ? map[val] : ''
        }
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        //判断当前页面
        findTabItem(title) {
            return this.tabList.find(item => item.title === title && item.sel);
        },
        desensitizeGet() {
            this.$emit('desensitizeGet')
        },
        getTemplateList(value) {
            this.$emit('getTemplateList', value)
        },
        firstExportBtn(type) {
            this.$emit('firstExportBtn', type)
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
