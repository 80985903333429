
<template>
    <div>
        <el-col :span="20">
            <el-cascader
                ref="cascaderRef"
                v-model="data.value"
                :props="data.cascaderProps"
                :disabled="data.disabled"
                :options="data.list"
                :collapse-tags="typeof data.collapseTags === 'undefined' ? true : data.collapseTags"
                :placeholder="(data && data.placeholder) ? data.placeholder : '请选择'"
                :clearable="typeof data.clearable !== 'undefined' ? data.clearable : true"
                filterable
                @change="changeSel(data)"
                :class="{collapseCascader: typeof data.collapseTags === 'undefined'}"
            ></el-cascader>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: 'Select',
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            changeSel (data) {
                this.$emit('changeCascader', {
                    data: data,
                    ref: this.$refs.cascaderRef
                })
                this.$eventDispatch('changeCascader', {
                    data: data,
                    ref: this.$refs.cascaderRef
                })
                // this.$eventBus.$emit('changeSel',data)
            }
        }
    }
</script>
<style lang="scss" scoped>
::v-deep .collapseCascader {
    width: 100%;

    .el-cascader__tags {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-right: 50px;

        .el-tag {
            max-width: calc(100% - 50px);

            + .el-cascader__search-input {
                margin-left: 6px;
                min-width: 50px;
            }
        }
    }
}
</style>
